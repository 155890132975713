import { createClient } from '@ennismore/redirect-config-client';
import { AxiosError } from 'axios';
import type { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import React from 'react';

import {
  BedroomAvailabilitySearchResults,
  useAvailabilityURLQuery,
  useEmitAvailabilitySearchFormEventHook,
  useIsExceedingGuestsPerRoomLimit,
} from '@/availability';
import { resolveRollout } from '@/availability-fallback';
import { HeroVideo } from '@/availability/components/HeroVideo.component';
import { LiveBookingWarningModal } from '@/availability/components/LiveBookingWarning.component';
import {
  AvailabilitySearchResultsBannersContainer,
  HotelAvailabilitySearchFormContainer,
} from '@/availability/containers';
import { navigateToAvailabilityResultsPage } from '@/availability/helpers/url';
import { AvailabilitySearchError } from '@/availability/models';
import { BookingStep } from '@/booking-checkout';
import { useCreateBooking } from '@/booking-creation';
import BookingWizardStepTitle from '@/booking/components/wizard/BookingWizardStepTitle.component';
import { HeaderWithWizardContainer } from '@/booking/components/wizard/HeaderWithWizard.container';
import { StepProgress } from '@/booking/components/wizard/StepProgress.component';
import { navigateToBookingAdditionalInformationPage } from '@/booking/helpers/url';
import {
  selectHotelConfigurationByCodeOrFail,
  selectHotelConfigurationByReferenceId,
  useActiveBrandConfig,
  usePageTitle,
} from '@/brand';
import { fetchBrandConfigForServerSidePropsContext } from '@/brand/common/services';
import { useSyncHotelCurrency } from '@/currency-preview/state';
import { getEnvironmentKey } from '@/env';
import {
  type ActiveExperiments,
  getAllExperimentsForRequest,
} from '@/flags/experiments';
import { ExperimentsProvider } from '@/flags/use-experiments';
import { useHotel } from '@/hotel';
import { resolveHotelReferenceIdFromHotelCode } from '@/hotel/codes';
import { useSetHotelTheme } from '@/hotel/hooks/use-set-hotel-theme';
import Footer from '@/hox-core/components/Footer.component';
import { useTranslation } from '@/i18n';
import { getLogger } from '@/logging';
import { metrics } from '@/telemetry/server';
import { deltaMs } from '@/telemetry/timing';
import {
  Card,
  FullScreenLayout,
  MobileWrapperPadding,
  PageWrapper,
} from '@/ui/layout';
import { Stack } from '@/ui/spacing';
import { useTheme } from '@/ui/theme';

interface AvailabilityProps {
  experiments?: ActiveExperiments;
}

const Availability: NextPage<AvailabilityProps> = function (props) {
  const { successType, validData, invalidData } = useAvailabilityURLQuery();
  const brandConfig = useActiveBrandConfig();
  const query = successType === 'full' ? validData : undefined;
  const setEmitParams = useEmitAvailabilitySearchFormEventHook();

  const hotelReferenceId = query?.hotelCode
    ? resolveHotelReferenceIdFromHotelCode(query.hotelCode)
    : undefined;

  const isExceedingGuestsPerRoomLimit = useIsExceedingGuestsPerRoomLimit(
    query,
    hotelReferenceId
  );

  const hotel = useHotel(hotelReferenceId);

  // If this view should expect to load search results...
  const hasValidSearchQuery =
    query && hotelReferenceId && !isExceedingGuestsPerRoomLimit;

  const { t } = useTranslation('search');
  const pageTitle = usePageTitle(t('title'));
  const createBooking = useCreateBooking();

  const { heroVideo, heroImage, forms } = useTheme();

  // Temporarily overrides themes that have a theme specified in their config
  useSetHotelTheme(hotel?.theme?.key);

  useSyncHotelCurrency(hotel?.payments.currencyCode);

  return (
    <ExperimentsProvider value={props.experiments}>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <HeaderWithWizardContainer
        currentPage={
          hasValidSearchQuery
            ? BookingStep.RoomSelection
            : BookingStep.SearchForm
        }
      />
      {!hasValidSearchQuery && heroVideo && <HeroVideo asset={heroVideo} />}
      {!hasValidSearchQuery && heroImage && (
        <Image
          src={heroImage.src}
          alt={heroImage.alt}
          width={heroImage.width}
          height={heroImage.height}
          style={{
            width: '100%',
            height: heroImage.height,
            objectFit: 'cover',
          }}
        />
      )}
      <LiveBookingWarningModal />
      <FullScreenLayout>
        <PageWrapper isFlushOnMobile>
          {hasValidSearchQuery ? (
            <BedroomAvailabilitySearchResults
              query={query}
              hotelReferenceId={hotelReferenceId}
              onRequestRedirect={(url) => {
                window.location.href = url;
              }}
              title={t('title')}
              onSearchQueryChange={(query) => {
                createBooking.clearError();
                setEmitParams({
                  query,
                  placement: 'booking engine modify search',
                });
                navigateToAvailabilityResultsPage(query);
              }}
              onRoomSelectionComplete={async (rooms) => {
                const result = await createBooking.createBooking({
                  query,
                  rooms,
                });

                if (result) {
                  navigateToBookingAdditionalInformationPage({
                    bookingId: result?.bookingId,
                    hotelSlug: selectHotelConfigurationByCodeOrFail(
                      brandConfig,
                      query.hotelCode
                    ).referenceId,
                  });
                }
              }}
              isCreatingBooking={
                // This keeps the loading spinner going once the booking has been created.
                // When the booking has been created, we push the new additional-information route to the router.
                // This will keep the loading spinner running indefinitely until the navigation event has completed.
                createBooking.isLoading || createBooking.isSuccess
              }
              createBookingError={createBooking.error}
              mobileStepProgress={<StepProgress total={3} current={1} />}
            />
          ) : (
            <>
              <MobileWrapperPadding>
                <BookingWizardStepTitle>
                  {t('titleOnSearchPage') || t('title')}
                </BookingWizardStepTitle>
              </MobileWrapperPadding>
              <Stack>
                {hotel && (
                  <AvailabilitySearchResultsBannersContainer
                    hotelSlug={hotel.referenceId}
                  />
                )}
                <Card
                  css={{
                    backgroundColor: forms?.searchForm?.backgroundColor,
                    display: forms?.searchForm?.display,
                    justifyContent: forms?.searchForm?.justifyContent,
                  }}
                >
                  <HotelAvailabilitySearchFormContainer
                    initialQuery={{
                      ...validData,
                      // This allows us to show the rooms data, minus childrenAges if childrenAges fails validation.
                      // This can happen if a user comes from a site which doesn't allow for the setting of child ages
                      // bur the property has a requirement for it
                      rooms: validData.rooms
                        ? validData.rooms
                        : invalidData.rooms,
                    }}
                    onSubmit={(query) => {
                      setEmitParams({
                        query,
                        placement: 'booking engine search',
                      });
                      navigateToAvailabilityResultsPage(query);
                    }}
                    error={
                      isExceedingGuestsPerRoomLimit
                        ? new AvailabilitySearchError({
                            code: 'EXCEEDED_NUMBER_OF_GUESTS_PER_ROOM',
                          })
                        : undefined
                    }
                  />
                </Card>
              </Stack>
            </>
          )}
        </PageWrapper>
      </FullScreenLayout>
      <Footer />
    </ExperimentsProvider>
  );
};

/**
 * Handles redirection if corresponding flag is enabled, and also is required
 * for the theme and brand configurations not to be baked into the page at build time.
 */
export const getServerSideProps: GetServerSideProps<AvailabilityProps> = async (
  ctx
) => {
  // If no hotelCode is included in the query, don't check if we should be redirecting this request
  if (typeof ctx.query.hotelCode !== 'string') {
    return {
      props: {},
    };
  }

  // Track timing
  const startTime = process.hrtime.bigint();

  const brandConfig = await fetchBrandConfigForServerSidePropsContext(ctx);

  const hotelReferenceId = resolveHotelReferenceIdFromHotelCode(
    ctx.query.hotelCode
  );

  const redirectConfig = await createClient(getEnvironmentKey())
    .getConfig(hotelReferenceId)
    .catch((err) => {
      if (err instanceof AxiosError && err.code === 'ERR_BAD_REQUEST') {
        getLogger().error(
          err,
          `Redirect config not found for hotel id: ${hotelReferenceId}`
        );
      } else {
        getLogger().error(
          err,
          `Failed to fetch redirect config for hotel: ${hotelReferenceId}`
        );
      }
      return undefined;
    });

  const rollout = await resolveRollout({
    brandConfig,
    redirectConfig,
    ...ctx,
  });

  // Measure how long the total check takes (sampling 25%)
  const duration = deltaMs(startTime);
  metrics().timing('rollout-check-duration-ms', duration, 0.25, {
    hotelReferenceId,
  });

  if (rollout.redirect) {
    // Track rollout fallback redirect event
    metrics().increment('fallback-redirect-count', {
      hotelReferenceId,
      provider: redirectConfig?.provider ?? '',
    });

    return {
      redirect: {
        destination: rollout.destinationUrl,
        permanent: false,
      },
    };
  }

  // We're serving an availability query to the user
  metrics().increment('serve-availability-query-count', {
    hotelReferenceId,
  });

  const experiments = await getAllExperimentsForRequest(ctx, {
    hotelReferenceId,
    brandReferenceId: brandConfig.chainCode,
    apiDialect: selectHotelConfigurationByReferenceId(
      brandConfig,
      hotelReferenceId
    )?.dialect,
  });

  return {
    props: {
      experiments,
    },
  };
};

export default Availability;
