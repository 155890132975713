import type { V1BookingRoom } from '@ennismore/booking-api-client';
import { useState } from 'react';

import { IAvailabilitySearchQueryInstance } from '@/availability/models';
import { useOriginalSearchQuery } from '@/booking-checkout';
import { CreateBookingError } from '@/booking/errors';
import {
  selectHotelConfigurationByCodeOrFail,
  useActiveBrandConfig,
} from '@/brand';
import { useLocale } from '@/i18n';

import { useCreateBookingMutation } from './use-create-booking-mutation.hook';

export const useCreateBooking = () => {
  const {
    mutateAsync,
    isLoading,
    isSuccess,
    data: createdBooking,
  } = useCreateBookingMutation();
  const [error, setError] = useState<CreateBookingError>();
  const brandConfig = useActiveBrandConfig();
  const locale = useLocale();
  const [, setOriginalSearchQuery] = useOriginalSearchQuery();

  return {
    async createBooking({
      query,
      rooms,
    }: {
      rooms: V1BookingRoom[];
      query: IAvailabilitySearchQueryInstance;
    }) {
      const { referenceId, dialect } = selectHotelConfigurationByCodeOrFail(
        brandConfig,
        query.hotelCode
      );

      try {
        const result = await mutateAsync({
          body: {
            hotelReferenceId: referenceId,
            locale: { language: locale.baseName },
            rooms,
            discountCode: query.rateCode,
            // Hardcoded for now https://ennismore.atlassian.net/browse/DEV-5045?focusedCommentId=30026
            discountCodeType: query.rateCode ? 'CORPORATE' : undefined,
          },
          dialect,
        });

        /**
         * Save the query used to search for this booking originally. This can be offered to the user if the booking later 404s.
         */
        setOriginalSearchQuery(result.bookingId, query);

        return result;
      } catch (error) {
        setError(error);
        console.log(error);
        return;
      }
    },
    isLoading,
    error,
    createdBooking,
    isSuccess,
    clearError() {
      setError(undefined);
    },
  };
};
