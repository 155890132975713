import type {
  V1CreateBookingRequest,
  V1CreateBookingResponse,
} from '@ennismore/booking-api-client';
import { useMutation } from 'react-query';

import {
  APIDialect,
  getDefaultApiRequestOptions,
  useEnnismoreApiClient,
  useEnnismoreApiClientConfig,
} from '@/api';
import '@/api';
import { useSkipRedirectSwitch } from '@/availability-fallback/hooks';
import { CreateBookingError } from '@/booking/errors';
import { OHIPBookingServiceClient } from '@/booking/services/ohip-booking.service';
import { mapNetworkError } from '@/errors';
import { getBookingGuestFromCount } from '@/hotel';

type Request = {
  body: V1CreateBookingRequest & { locale: { language: string } };
  dialect: APIDialect;
};

export const useCreateBookingMutation = () => {
  const client = useEnnismoreApiClient();
  const [skipRedirect] = useSkipRedirectSwitch();
  const clientConfig = useEnnismoreApiClientConfig();

  return useMutation<V1CreateBookingResponse, CreateBookingError, Request>(
    async ({ body, dialect }) => {
      try {
        // @TODO Move this out to depend on an abstract service interface, resolving correct concrete for ohip/ows
        switch (dialect) {
          case 'ows': {
            const { data } =
              await client.bookingApi.bookingServiceCreateBooking(
                {
                  hotelReferenceId: body.hotelReferenceId,
                  from: body.rooms[0].checkInDate,
                  to: body.rooms[0].checkOutDate,
                  rateCode: body.rooms[0].rateCode,
                  roomRequest: body.rooms.map((room) => ({
                    guest: getBookingGuestFromCount(room.guests),
                    roomCode: room.roomCode,
                    twin: false, // TODO: hardcoded, fix please
                  })),
                },
                getDefaultApiRequestOptions(body.locale.language, {
                  skipRedirect,
                })
              );
            return data;
          }
          case 'ohip':
            return await new OHIPBookingServiceClient(
              clientConfig
            ).createBooking(body);
        }
      } catch (e) {
        console.error(e);
        throw new CreateBookingError(await mapNetworkError(e));
      }
    }
  );
};
