import { css } from '@emotion/react';
import React from 'react';

import { Text } from '@/ui/typography';

import { useTheme } from '../theme';

interface WizardStepProps {
  stepNumber?: number;
  title: string;
  onClick?: () => void;
  isActive?: boolean;
}

export const WizardStep = (props: WizardStepProps) => {
  const {
    space,
    colors,
    componentProperties: { header },
  } = useTheme();

  const wizardStepStyles = header?.wizard?.step;

  const handleClick = props.onClick;

  return (
    <button
      css={css({
        margin: `0 ${space[3]} 0 ${space[3]}`,
        display: 'flex',
        cursor: 'pointer',
        position: 'relative',
        touchAction: 'manipulation',
        ['&:focus:after']: {
          transform: 'scaleX(1)',
          transformOrigin: 'right',
        },
        ['&:after']: {
          content: "''",
          display: 'block',
          position: 'absolute',
          backgroundColor: `${colors.textPrimary}`,
          height: '1px',
          width: '100%',
          bottom: '-4px',
          willChange: 'transform',
          transform: 'scaleX(0)',
          transformOrigin: 'left',
          left: '0',
          transition: 'transform 0.3s ease-in-out',
        },
        ['@media all and (max-width: 730px)']: wizardStepStyles?.mobile,
        ['@media all and (max-width: 359px)']: {
          display: 'none',
        },
        span: {
          opacity: props.isActive ? 1.0 : 0.8,
          ...wizardStepStyles,
          ...(props.isActive ? wizardStepStyles?.active : {}),
        },
        ...wizardStepStyles,
        ...(props.isActive ? wizardStepStyles?.active : {}),
        // Prevent duplicate opacity application; should only be added to <span>
        opacity: 1,
      })}
      role="button"
      onClick={handleClick}
      aria-label={`Go to ${props.title}`}
      tabIndex={0}
    >
      {props.stepNumber && (
        <div
          css={css`
            margin-right: ${space[2]};
          `}
        >
          <Text
            style="bodySmall"
            fontFamily={
              wizardStepStyles?.fontFamily ??
              wizardStepStyles?.active?.fontFamily
                ? 'inherit'
                : undefined
            }
          >
            {props.stepNumber}
          </Text>
        </div>
      )}
      <Text
        style="bodySmall"
        fontFamily={
          wizardStepStyles?.fontFamily ?? wizardStepStyles?.active?.fontFamily
            ? 'inherit'
            : undefined
        }
      >
        {props.title}
      </Text>
    </button>
  );
};

export default WizardStep;
