import React from 'react';

import type { Video } from '@/ui/theme';
import { BackgroundVideo } from '@/ui/video';

export const HeroVideo = ({ asset }: { asset: Video }) => (
  <BackgroundVideo
    src={asset.src}
    height={asset.height}
    style={{ aspectRatio: 'auto' }}
  />
);
